import WarningSharpIcon from '@mui/icons-material/WarningSharp';
import React from "react";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const ModIns = ({ meta = {} }) => {
  return (
    <div className="DashEnseignementsMOD">
      <Stack sx={{ width: '100%' }} spacing={2}>
        {/* Alertes sur les conditions de sélection */}
        <Alert variant="outlined" severity="warning">
          <strong>Modalités d'inscription et conditions de sélection :</strong>
          <div className="__inner">
            <div className="__group">
              <div className="__item">
                <span>
                  <WarningSharpIcon />
                </span>
                <span>
                  Vous devez choisir entre <strong>{meta.minV || "N/A"}</strong> et <strong>{meta.maxV || "N/A"}</strong> vœux.
                </span>
              </div>
            </div>

            <div className="__group">
              <div className="__item">
                <span>
                  <WarningSharpIcon />
                </span>
                <span>
                  Chaque vœu doit contenir entre <strong>{meta.minO || "N/A"}</strong> et <strong>{meta.maxO || "N/A"}</strong> options.
                </span>
              </div>
            </div>

            <div className="__group">
              <div className="__item">
                <span>
                  <WarningSharpIcon />
                </span>
                <span>
                  Le quota horaire total pour un vœu doit être compris entre <strong>{meta.minHW || "N/A"}</strong> et <strong>{meta.maxHW || "N/A"}</strong> heures.
                </span>
              </div>
            </div>
          </div>
        </Alert>

        {/* Instructions pour l'utilisateur */}
        <Alert variant="outlined" severity="info">
          <strong>Comment sélectionner vos options ?</strong>  
          <br />
          <ul>
            <li>Sélectionnez un <strong>vœu</strong> dans la zone située à droite.</li>
            <li>Cliquez sur les <strong>options</strong> que vous souhaitez ajouter à ce vœu.</li>
            <li>Pour retirer une option d’un vœu, <strong>désélectionnez-la</strong>.</li>
          </ul>
        </Alert>
      </Stack>
    </div>
  );
};

export default ModIns;
