import "./style.scss";

import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import cx from "classnames";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';

import { sGetAllItems } from "../../../../../libs/storage";
import { GET_VOEUX, POST_VOEUX, PUT_VOEUX, DELETE_VOEUX } from "../../../../../services";
import {
  canAddSelection,
  formatSaveVoeux,
  formatStoredVoeux,
  getIntersectOptions,
  getFilteredOptions,
  getSaveChecks,
  validateEnsData
} from "../../../../../libs/helpers";

import ManageBox from "../../../../../components/ManageBox";
import DragList from "./DragListN";
import Item from "./Item";
import ModIns from "./ModIns";

const DashEnseignementsManage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const eId = params?.id;
  const { enqueueSnackbar } = useSnackbar();
  // ...
  const [isLoading, setIsLoading] = useState(false);
  const [isVoeuxLoading, setIsVoeuxLoading] = useState(false);
  const hasSavedVoeux = useRef(false);
  // ...
  const [isEdit, setIsEdit] = useState({ id: null, state: false });
  const [currentVoeuxId, sertCurrentVoeuxId] = useState(null);
  const [ensDataErr, setEnsDataErr] = useState(false);
  const [voeuxDataErr, setVoeuxDataErr] = useState(false);
  const [BASE_ENS, SET_BASE_ENS] = useState({});
  const [META, SET_META] = useState({
    curV: 0,
    minV: 0,
    maxV: 0,
    // ...
    minO: 0,
    maxO: 0,
    // ...
    minHW: null,
    maxHW: null
  });

  //# Base Options
  const MOD_INS = BASE_ENS?.modalite_inscription_ens || {};
  const ensOpts = (BASE_ENS?.ens?.options || []).map((o) => o.option);
  const redOpts = MOD_INS?.liste_opt_reduites || [];
  const baseOpts = getFilteredOptions(ensOpts, redOpts);
  // ...
  const [currBlock, setCurrBlock] = useState(0);
  const [options, setOptions] = useState([]);
  const [cards, setCards] = useState([]);
  // ...
  const optsIds = _.flatten(options, true) || [];
  // ...
  const onDisplayAlert = (msg = "", isError = false) => {
    enqueueSnackbar({ msg, isError });
  };
  // ...
  const onCheckHandler = (opt, s) => {
    let quotaHW = cards[currBlock].reduce((t, o) => (o.poid_horaire || 0) + t, 0);

    const { canAdd, reason } = canAddSelection({ meta: META, opt, currOpts: cards[currBlock], checked: s, quotaHW });

    if (!canAdd) {
      onDisplayAlert(reason, true);
    }

    // ...
    let tmp = [...options];
    let tmpBlock = tmp[currBlock] || [];

    // ...
    if (s) {
      if (canAdd) tmp[currBlock] = [...tmpBlock, opt.id];
    } else {
      tmp[currBlock] = tmpBlock.filter((oId) => oId !== opt.id);
    }
    // ...
    setOptions(tmp);
  };
  // ...
  const onAddVoeuxHandler = () => {
    const curV = META.curV + 1;
    if (curV > META.maxV) return;
    // ...
    SET_META((s) => ({ ...s, curV }));
    setOptions((s) => [...s, []]);
    setCards((s) => [...s, []]);
  };
  // ...
  const onDeleteVoeuxHandler = (idx) => {
    const curV = META.curV - 1;
    if (curV < META.minV) return;
    // ...
    SET_META((s) => ({ ...s, curV }));
    setOptions((s) => s.filter((x, i) => i !== idx));
    setCards((s) => s.filter((x, i) => i !== idx));
  };
  // ...
  const getSavedVoeux = async (eId, baseOpts) => {
    setIsVoeuxLoading(true);
    const userId = sGetAllItems()?.USER?.id;
    const { data, error } = await GET_VOEUX(userId, eId);
    sertCurrentVoeuxId(data?.data?.[0]?.id);
    // ...
    if (error) {
      onDisplayAlert("Unable to get voeux list, please try again", true);
      setVoeuxDataErr(true);
    } else {
      const options = formatStoredVoeux(data, baseOpts);
      const optsL = options.length;
      if (!!optsL) {
        setIsEdit({ id: data?.data?.[0].id, state: true });
        SET_META((s) => ({ ...s, curV: optsL }));
        setCards(Array.from({ length: optsL }).fill([]));
        setOptions(options);
      }
    }
    // ...
    setIsVoeuxLoading(false);
  };

  // ...
  const onReset = () => {
    const iVal = Array.from({ length: META?.curV }).fill([]);
    setOptions(iVal);
    setCurrBlock(0);
  }
  // ...
  const onClear = async () => {
    const iVal = Array.from({ length: META.curV }).fill([]);
    setOptions(iVal);
    setCards(iVal);
  };
  // ...
  const onSave = async () => {
    if (isLoading) return;
    const minO = META?.minO || 0;

    // ...
    let { canSave, index, reason } = getSaveChecks(META.minO, options, META, baseOpts);
    if (!canSave) {
      // ...
      setCurrBlock(index);
      onDisplayAlert(reason, true);
    } else {
      setIsLoading(true);
      const req = { data: formatSaveVoeux(BASE_ENS, baseOpts, options) };
      const reqHasVoeux = !!req?.data?.voeux?.reduce((x, y) => x + y.choix.length, 0);
      let res = {};
      // ...
      if (isEdit.state) {
        if (reqHasVoeux) PUT_VOEUX(isEdit.id, req);
        else res = await DELETE_VOEUX(isEdit.id);
      } else {
        res = await POST_VOEUX(req);
        if (res?.data?.response?.data?.error?.message === "VOEUX_EXIST") {
          res = await PUT_VOEUX(currentVoeuxId, req);
        }
      }
      // ...
      if (res.error) {
        onDisplayAlert("Unable to save voeu list, please try again", true);
      } else {
        onDisplayAlert("Vos vœux ont été enregistrés");
      }
      // ...
      setIsLoading(false);
      navigate("/dashboard/enseignements", { replace: true });
    }
  };
  // ...
  useEffect(() => {
    let tmp = options.map((x) => []);
    // ...
    options.forEach((b, bIdx) => {
      b.forEach((oId) => {
        baseOpts.forEach((opt) => {
          if (oId === opt.id) tmp[bIdx].push(opt);
        });
      });
    });
    // ...
    setCards(tmp);
  }, [options]);
  // ...
  useEffect(() => {
    if (currBlock > options.length - 1) setCurrBlock(0);
  }, [currBlock, options]);
  // ...
  useEffect(() => {
    if (!eId || hasSavedVoeux.current) return;
    hasSavedVoeux.current = true;
    // ...
    const raw = sGetAllItems();
    let ensTarget = {};
    // ...
    raw?.PROG?.ens_et_mod_ins?.forEach((ens) => {
      const ensID = ens.ens.id;
      if (eId === ensID.toString()) ensTarget = ens;
    });

    //# Validate Ens Data
    const ensDataErr = validateEnsData(ensTarget);
    // ...
    if (ensDataErr.err) {
      setEnsDataErr(true);
      SET_BASE_ENS(ensTarget);
    } else {
      //# Meta
      const mod = ensTarget?.modalite_inscription_ens || {};
      const minV = mod.nbr_voeux_a_effectuer_min;
      const maxV = mod.nbr_voeux_a_effectuer_max;
      const minO = mod.nbr_options_a_choisir_min;
      const maxO = mod.nbr_options_a_choisir_max;
      const minHW = mod.poid_horaire_min;
      const maxHW = mod.poid_horaire_max;
      // ...
      const curV = minV;
      // ...
      SET_META((s) => ({ ...s, curV, minV, maxV, minO: parseInt(minO), maxO, minHW, maxHW }));
      SET_BASE_ENS(ensTarget);

      //# Init Options/Cards
      const iVal = Array.from({ length: curV }).fill([]);
      setOptions(iVal);
      setCards(iVal);

      //# Get Store Voeux
      const ensOpts = (ensTarget?.ens?.options || []).map((o) => o.option);
      getSavedVoeux(eId, ensOpts);
    }
  }, [eId]);

  //# Loading DATA/Voeux
  if (isLoading || isVoeuxLoading)
    return (
      <ManageBox
        title={BASE_ENS.libelle || ""}
        canClear={false}
        canNext={false}
        isLoading={false}
        actions={{ onClear: () => { }, onSave: () => { }, close: "/dashboard/enseignements" }}>
        <div className="DashEnseignementsError">
          <CircularProgress className="__icon" />

          <div className="__content">
            <p>Veuillez patienter nous chargeons vos données</p>
          </div>
        </div>
      </ManageBox>
    );

  //# EnsData has Err
  if (ensDataErr || voeuxDataErr)
    return (
      <ManageBox
        title={BASE_ENS.libelle || ""}
        canClear={false}
        canNext={false}
        isLoading={false}
        actions={{ onClear: () => { }, onSave: () => { }, close: "/dashboard/enseignements" }}>
        <div className="DashEnseignementsError">
          <WarningAmberIcon className="__icon" />

          <div className="__content">
            {ensDataErr && <p>Une des options est mal configurée</p>}
            {voeuxDataErr && <p>Unable to get voeu list, please try again</p>}
          </div>
        </div>
      </ManageBox>
    );


  // ...
  return (
    <ManageBox
      title={BASE_ENS.libelle || ""}
      canClear={_.flatten(options || [])?.length > 0}
      canNext={true}
      isLoading={isLoading}
      actions={{ onClear, onSave, close: "/dashboard/enseignements" }}>
      <ModIns meta={META} />

      <div className="DashEnseignementsManage">
        <div className="DashEnseignementsManage__item DashEnseignementsManage__base">
          <div className="__header">
            <span>Liste des options</span>
          </div>
          <div className="__body">
            {baseOpts.map((card, index) => {
              let disabled = false;
              let checked = optsIds.indexOf(card.id) > -1;
              let cvIdx = null;
              // ...
              options.forEach((vOpts, vIdx) => {
                if (vOpts.indexOf(card.id) > -1) {
                  if (checked) cvIdx = vIdx + 1;
                  if (vIdx === currBlock) disabled = true;
                }
              });
              // ...
              return (
                <Item
                  key={index}
                  data={card}
                  checked={checked}
                  disabled={!disabled}
                  cvIdx={cvIdx}
                  // ...
                  options={options}
                  // ...
                  onCheckHandler={onCheckHandler}
                />
              );
            })}
          </div>
        </div>

        <div className="DashEnseignementsManage__sep" />

        <div className="DashEnseignementsManage__item DashEnseignementsManage__selected">
          <div className="__header">
            <span>Liste des voeux</span>
            {META.curV < META.maxV && (
              <div className="__add" onClick={onAddVoeuxHandler}>
                <AddIcon />
              </div>
            )}
          </div>
          <div className="__body">
            {Array.from({ length: META.curV })
              .fill("VOEUX")
              .map((d, idx) => {
                return (
                  <Accordion
                    key={idx}
                    expanded={true}
                    className={cx("__block", { __curr: currBlock === idx })}
                    onChange={() => setCurrBlock(idx)}
                    onClick={() => setCurrBlock(idx)}
                  >
                    <AccordionSummary
                      className={cx("__block__header", { __curr: currBlock === idx })}
                      aria-controls="panel1a-content"
                      style={{
                        borderBottom: "1px solid #ccc",  // Add a border between the summary and details
                        padding: "12px 16px",
                      }}
                    >
                      <div className="__label">
                        <span>
                          Vœu {idx + 1}
                          {META?.minHW !== null && META?.maxHW !== null &&
                            ` - [Quota horaire entre ${META.minHW} et ${META.maxHW}]`}
                        </span>
                      </div>

                      {META.curV > META.minV && (
                        <DeleteIcon
                          className="__del-icon"
                          onClick={(e) => {
                            e.stopPropagation();  // Prevent the event from triggering the accordion change
                            onDeleteVoeuxHandler(idx);
                          }}
                        />
                      )}
                    </AccordionSummary>
                    <AccordionDetails
                      className="__block__body"
                      style={{
                        padding: "16px",
                      }}
                    >
                      {/* <div className="__conditions">
                        <CheckCircleIcon/>
                        <span>Quota horaire : {cards?.[idx]?.reduce((t, o) => (o.poid_horaire || 0) + t, 0)}</span>
                        <ErrorSharpIcon/>
                        <span>Quota : {cards?.[idx]?.reduce((t, o) => (o.poid_horaire || 0) + t, 0)}</span>
                      </div> */}
                      <div>
                        <DragList idx={idx} cards={cards} />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </div>
        </div>
      </div>
    </ManageBox>
  );
};

export default DashEnseignementsManage;
