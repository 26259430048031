import { DateTime } from "luxon";

import { sGetAllItems } from "./storage";

export const getDataToSave = (data) => {
  let ens_et_mod_ins = data?.programme?.ens_et_mod_ins?.filter((ens, idx) => {
    const now = DateTime.local().toUTC().toISO();
    const start = ens.date_ouverture_inscritption;
    const end = ens.date_fermeture_inscritption;
    // ...
    if (!ens.inscription_en_ligne || !start || !end) return false;
    return start <= now && end > now;
    // return true;
  });
  // ...
  return {
    ...data,
    programme: { ...(data?.programme || {}), ens_et_mod_ins },
    voeux: data?.voeux || []
  };
};

export const validateEnsVoeuxList = (ensList, voeuxList) => {
  return ensList.map((e) => {
    const minB = e.modalite_inscription_ens?.nbr_options_a_choisir_min;
    const minO = parseInt(minB) <= 1 ? minB : 0;
    const ensVoeux = voeuxList.filter((v) => e.ens.id === v.enseignement.id);
    // ...
    return ensVoeux.length >= minO;
  });
};

export const canAddSelection = ({ meta, opt, currOpts, checked }) => {
  if(checked){
    currOpts?.push(opt);
  }
  
  if (currOpts.length > meta.maxO) {
    return { 
      canAdd: false, 
      reason: "Le nombre maximum d'options pour ce vœu a été atteint." 
    };
  }

  if (typeof meta.maxHW !== "number") return { canAdd: true, reason: "" };
  if (typeof meta.minHW !== "number") return { canAdd: true, reason: "" };

  // ...
  const optHW = opt.poid_horaire || 0;
  const optsHW = currOpts.reduce((t, o) => (o.poid_horaire || 0) + t, 0);
  // ...
  if (optsHW > meta.maxHW && checked) {
    return { 
      canAdd: false, 
      reason: "Le vœu courant dépasse le maximum d'heures autorisé." 
    };
  }
  // ...
  return { canAdd: true, reason: "" };
};

export const formatStoredVoeux = (raw, baseOpts) => {
  const voeux = raw?.data?.[0]?.attributes?.voeux || [];
  return voeux?.map((v) =>
    v.choix.map((c) => {
      let id = null;
      // ...
      baseOpts.forEach((opt) => {
        if (opt.libelle === c.libelle) id = opt.id;
      });
      // ..
      return id;
    })
  );
};

export const formatSaveVoeux = (baseEns, baseOpts, options) => {
  const b = sGetAllItems();
  const userId = b.USER?.id;
  // ...
  return {
    etudiant: userId,
    enseignement: baseEns.ens.id,
    // ...
    libelle: baseEns.ens.libelle,
    voeux: options.map((b, bIdx) => {
      let str = b.map((oId) => {
        let opt = {};
        // ...
        baseOpts.forEach((bo) => {
          if (bo.id === oId) opt = bo;
        });
        // ...
        return opt.libelle.split(" ")?.[0];
      })
      // console.log(str);
      // ...
      return {
        libelle: `Voeu ${bIdx + 1}: ( ${str.join(' - ')} )`,
        choix: b.map((oId) => {
          let opt = {};
          // ...
          baseOpts.forEach((bo) => {
            if (bo.id === oId) opt = bo;
          });
          // ...
          return { libelle: opt.libelle, option: opt.id };
        })
      };
    })
  };
};

export const getIntersectOptions = (base, reduced) => {
  if (!reduced?.length) return base;
  // ...
  const reducedIds = reduced.map((opt) => opt.id);
  return base.filter((opt) => reducedIds.indexOf(opt.id) > -1);
};

export const getFilteredOptions = (base, reduced) => {
  if (!reduced?.length) return base;
  // ...
  const reducedIds = new Set(reduced.map((opt) => opt.id));
  return base.filter((opt) => !reducedIds.has(opt.id));
};

export const getSaveChecks = (mo, opts, META, baseOpts) => {
  let out = { canSave: true, reason: "", index: -1 };
  const minOL = mo === 1 ? "1 option" : mo + " options";

  // New condition: if curV equals minV and at least one voeux is not empty
  // if (META.curV === META.minV && opts.some(opt => opt.length > 0)) {
  //   return {
  //     canSave: false,
  //     reason: "Vous devez finir tous les vœux.",
  //     index: -1, // No specific index, applies to all
  //   };
  // }

  // Loop through each "voeu" (selection block)
  for (let i = 0; i < opts.length; i++) {
    if (opts[i].length < mo) {
      out = {
        canSave: false,
        reason: `Veuillez sélectionner un minimum de ${minOL} pour le vœu sélectionné.`,
        index: i,
      };
      break; // Exit loop once condition is met
    }

    // Calculate total poid_horaire for the selected options in this vœu
    let totalPoidHoraire = opts[i].reduce((sum, id) => {
      const foundOpt = baseOpts.find(opt => opt.id === id);
      return foundOpt ? sum + (foundOpt.poid_horaire || 0) : sum;
    }, 0);

    // Check constraints for minHW and maxHW
    if (META.maxHW !== null && totalPoidHoraire > META.maxHW) {
      out = {
        canSave: false,
        reason: `Le poids horaire total (${totalPoidHoraire}) dépasse la limite maximale de ${META.maxHW}.`,
        index: i,
      };
      break; // Stop checking further
    }

    if (META.minHW !== null && totalPoidHoraire < META.minHW) {
      out = {
        canSave: false,
        reason: `Le poids horaire total (${totalPoidHoraire}) est inférieur à la limite minimale de ${META.minHW}.`,
        index: i,
      };
      break; // Stop checking further
    }
  }

  return out;
};

export const validateEnsData = (ens) => {
  let out = { err: false, data: "" };
  // ...
  const data = ens?.ens || {};
  const options = data.options || [];

  //# Validate Ens Options
  options.forEach((o) => {
    if (!o.option) out = { err: true, data: "OPTION" };
  });

  // ...
  return out;
};
